.MapPage {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 200px;
}

.map-toggle {
  display: flex;
  position: absolute;
  bottom: 3%;
  right: 4%;
  z-index: 2;
  color: #F3684A;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  background: #FFFFFF;
  padding: 10px;
  border: 2px solid #F3684A;
  border-radius: 7px;
}

.map-search {
  position: absolute;
  top: 60px;
  background: #FFFFFF;
  z-index: 101;
  display: flex;
  width: 380px;
}


.map-search-input {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 29px;
  color: #F3684A;
}

.button-container {
  position: absolute;
  top: 60px;
  right: 1%;
  display: flex;
  flex-direction: row;
}

.selected-button {
  background-color: #F3684A;
  color: #FFFFFF; 
}

.unselected-button {
  background: #FFFFFF;
  color: #F3684A;
}

.button {
  width: 140px;
  height: 40px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 29px;
  border: 2px solid #F3684A;
  border-radius: 7px;
  z-index: 2;
  cursor: pointer;
  margin-left: 10px;
}
