.navbar {
    background-color: #F3684A;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 200px;
    display: flex;
    flex-direction: column;
    padding-top: 1rem;
}

.navbar-icon {
    vertical-align: middle;
    margin-right: 5px;
    color: inherit;
}

.navbar-logo {
    width: 200px;
    height: 100px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    margin-bottom: 1rem;
}

.navbar-links {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
}

.navbar-link {
    display: block;
    color: #FFFFFF;
    text-decoration: none;
    font-size: 18px;
    text-align: left;
    padding: 15px;
    font-family: 'Inter';
    font-weight: 500;
}

.navbar-links li {
    padding: 0.5rem 0;
    width: 200px;
}

.navbar-links .navbar-link.active-link {
    background-color: #FFFFFF;
    color: #F3684A;
}

.logout-container {
    margin-top: auto;
    width: 100%;
    padding-bottom: 30px;
}

.logout-button {
    background: none;
    border: none;
    width: 100%;
    padding: 10px;
    text-align: center;
}
