.register-container {
  background: #FFFFFF;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-family: 'Inter';
  font-weight: 500;
}

.logo {
  width: 50%;
  height: 200px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin-bottom: 2rem;
}

@media (max-width: 1200px) {
  .logo {
    height: 200px;
  }
}

@media (max-width: 992px) {
  .logo {
    height: 175px;
  }
}

@media (max-width: 768px) {
  .logo {
    height: 150px;
  }
}

.register-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 530px;
  background: #FFFFFF;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.input-field {
  box-sizing: border-box;
  width: 100%;
  height: 68px;
  background: #FFFFFF;
  border: 2px solid #F3684A;
  margin-bottom: 1rem;
  padding: 0 15px;
  font-size: 18px;
}

.register-button {
  box-sizing: border-box;
  width: 100%;
  height: 68px;
  background: #F3684A;
  border: 2px solid #F3684A;
  color: white;
  margin-bottom: 1rem;
  font-size: 18px;
}

.login-link {
  text-align: center;
  width: 100%;
  font-size: 18px;
}

.login-link-text {
  color: #F3684A;
  font-size: 18px;
}

.section-title {
  font-size: 24px;
  font-weight: bold;
  text-align: left;
  width: 100%;
  margin-bottom: 1rem;
}

.select-field {
  width: 100%;
  margin-bottom: 1rem;
}

