.taste-profile {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1rem;
  max-width: 1300px;
  margin: 0 auto;
  font-family: 'Inter';
  font-weight: 500;
}

.taste-profile h1 {
  color: #F3684A;
  font-size: 36px;
  font-weight: bold;
}

.taste-profile p {
  font-size: 24px;
  margin-bottom: 3rem;
  text-align: left;
}

.taste-profile .key {
  color: #F3684A;
}

.welcome-message {
  text-align: left;
}

.welcome-subtitle {
  font-size: 1.25em;
  font-weight: bold;
}

.profile-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 5rem;
}

.profile-info {
  flex: 1;
  margin-left: 10px;
}

.profile-settings {
  flex: 1;
  margin-left: 5rem;
}

