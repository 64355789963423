.register-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 530px;
  background: #FFFFFF;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.input-field {
  box-sizing: border-box;
  width: 100%;
  height: 68px;
  background: #FFFFFF;
  border: 2px solid #F3684A;
  margin-bottom: 1rem;
  padding: 0 15px;
  font-size: 18px;
  font-family: 'Inter';
  font-style: normal;
}

.register-button {
  box-sizing: border-box;
  width: 100%;
  height: 68px;
  background: #F3684A;
  border: 2px solid #F3684A;
  color: white;
  margin-bottom: 1rem;
  font-size: 12px;
}

.login-link {
  text-align: center;
  width: 100%;
  font-size: 12px;
}

.login-link-text {
  color: #F3684A;
  font-size: 12px;
}

.section-title {
  font-size: 18px;
  font-weight: bold;
  text-align: left;
  width: 100%;
  margin-bottom: 1rem;
}

.select-field {
  width: 100%;
  margin-bottom: 1rem;
}

.update-button {
  color: #FFFFFF;
  background: #F3684A;
  border: 2px solid #F3684A;
  border-radius: 7px;
  padding: 10px 20px;
  margin-top: 5px;
  cursor: pointer;
  font-size: 18px;
  font-family: 'Inter';
  font-style: normal;
}