.modal-content {
    background-color: white;
    border-radius: 5px;
    padding: 0px;
    width: 100%;
    max-width: 600px;
    box-sizing: border-box;
    max-height: 80vh;
    overflow-y: auto;
  }
  
  .friends-container {
    width: 90%;
    font-family: 'Inter';
    font-style: normal;
  }
  
.friends-title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    line-height: 29px;
    color: #000000;
    margin-bottom: 16px;
    margin-top: 65px;
    font-size: 45px;
    color: #F3684A;
    margin-bottom: 5%;
}

.friends-inputs {
    display: flex;
    justify-content: space-between;
    padding: 20px 100px;
    flex-direction: column;
    align-items: flex-start;
}

.paragraph {
    margin: 0;
    padding: 20px;
    font-size: 40px;
}

.search-friends {
    box-sizing: border-box;
    width: 500px;
    height: 63px;
    background: #FFFFFF;
    border: 2px solid #F3684A;
    padding: 20px;
    font-size: 20px;
    margin-left: 20px;
    font-family: 'Inter';
    font-style: normal;
}

.friend-code-input {
    box-sizing: border-box;
    width: 500px;
    height: 63px;
    background: #FFFFFF;
    border: 2px solid #F3684A;
    border-radius: 7px;
    margin-right: 8px;
    padding: 20px;
    font-size: 20px;
}

.username {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    color: #000000;
}

.add-friend-btn {
    background: #FFFFFF;
    background-color: transparent;
    cursor: pointer;
    padding: 15px;
    border: 0;
}

.user-list {
    list-style: none;
    padding-top: 20px;
    margin-left: 70px;
    width: 92%;
}

.user-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
}

.user-icons {
    display: flex;
    align-items: center;
}

.user-icons-profile {
    vertical-align: middle;
}

button {
    background-color: transparent;
    cursor: pointer;
    margin-left: 8px;
    border: 0;
}

.modal-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}
  
  
  .modal-content {
    background-color: white;
    border-radius: 5px;
    padding: 0px;
    width: 90%;
    max-width: 600px;
    box-sizing: border-box;
}

  
  